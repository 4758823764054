.form-actions button{
  margin: 2px;
}

.cdk-overlay-container {
  z-index: 1050
}

.invible{
  display: none;
}

.DuplicatedRow{
  background-color: lightsteelblue !important;
}
mat-chip-list{
  font-family: 'IRANSansWeb' !important
}
mat-panel-title {
  font-family: 'IRANSansWeb' !important
}
mat-panel-description{
  font-family: 'IRANSansWeb' !important
}
mat-chip{
  font-family: 'IRANSansWeb' !important
}
mat-cell {
  font-family: 'IRANSansWeb' !important
}

mat-label {
  font-family: 'IRANSansWeb' !important
}

input {
  font-family: 'IRANSansWeb' !important
}

button {
  font-family: 'IRANSansWeb' !important
}

matInput {
  font-family: 'IRANSansWeb' !important
}

.mat-row:nth-child(even){
  background-color: white;
}
      
.mat-row:nth-child(odd){
  background-color: rgba(0, 0, 0, 0.027);
}

.mat-paginator-page-size-label,
.mat-paginator-range-label{
  font-family: 'IRANSansWeb' !important
}